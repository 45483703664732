import utils from "./utils";

const { ethers } = require("ethers");
const ERC721 = require("./abis/ERC721.json");
const ERC20 = require("./abis/ERC20.json");

const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;

async function getNFTContract({ nftAddress, provider }) {
  if (nftAddress.length !== testAddyLength) {
    return { error: "NFT Address is not valid" };
  }
  let a = ethers.utils.getAddress(nftAddress);
  try {
    const nftContract = new ethers.Contract(a, ERC721.abi, provider);
    return { contract: nftContract };
  } catch (e) {
    return { error: e };
  }
}

async function getERC20Contract({ address, provider }) {
  if (address.length !== testAddyLength) {
    return { error: "Token Address is not valid" };
  }
  let a = ethers.utils.getAddress(address);

  try {
    const tokenContract = new ethers.Contract(a, ERC20.abi, provider);
    return { contract: tokenContract };
  } catch (e) {
    return { error: e };
  }
}

async function getTokenURI({ contract }) {
  try {
    return await contract.tokenURI(0);
  } catch (e) {
    //(e);
  }
}

async function getNFTContractData({ nftAddress, provider, needImage }) {
  try {
    const { contract, error } = await getNFTContract({
      nftAddress: nftAddress,
      provider: provider,
    });
    if (error) {
      //("GETNFT CONTRACT DATA ERROR: ", error);
      return { error: error };
    }
    const [name, symbol, tokenURI] = await Promise.all([
      contract.name(),
      contract.symbol(),
      getTokenURI({ contract: contract }),
      //   contract.totalSupply(),
    ]);
    let image;

    if (needImage && tokenURI) {
      try {
        const nftMetadata = await utils.getNFTJson(tokenURI);
        if (nftMetadata.image) {
          image = nftMetadata.image;
        }
      } catch (e) {
        //(e);
      }
    }
    //("IMAGE", image);

    return {
      contract: contract,
      name: name,
      symbol: symbol,
      image: image,
      logoURI: image,
    };
  } catch (e) {
    return { error: e };
  }
}

async function getERC20ContractData({ address, provider }) {
  try {
    const { contract, error } = await getERC20Contract({
      address: address,
      provider: provider,
    });
    if (error) {
      return { error: error };
    }
    const [name, symbol, decimals] = await Promise.all([
      contract.name(),
      contract.symbol(),
      contract.decimals(),
      //   contract.totalSupply(),
    ]);

    return {
      contract: contract,
      name: name,
      symbol: symbol,
      decimals: decimals,
    };
  } catch (e) {
    return { error: e };
  }
}

const ethereumUtils = {
  getNFTContract,
  getNFTContractData,
  getERC20ContractData,
};

export default ethereumUtils;
