import ky from "ky";

const bondPrefixUrl = "https://api.schedule.bond/v1/";
//const bondPrefixUrl = "http://localhost:8888/v1/";

const _bondAPI = ky.create({ prefixUrl: bondPrefixUrl });

async function bondApiCall(path, body, jwt) {
  const headers = {
    "Content-Type": "application/json",
    authorization: jwt ? jwt : undefined,
  };
  
  try {
    const response = await _bondAPI
      .post(path, {
        json: body,
        headers: headers,
      })
      .json();
    return response;
  } catch (e) {
    console.log(e)
    return { error: e.message };
  }
}

async function createLogin() {
  const path = "createLogin";
  const body = {};

  return await bondApiCall(path, body);
}

async function login({ message, signature }) {
  const path = "login";
  const body = {
    message: message,
    signature: signature,
  };

  const response = await bondApiCall(path, body);

  if (response.error === "jwt expired") {
    response.error = "Please Login Again";
  }
  return response;
}

async function getUserData({ address, username, userId, jwt }) {
  const path = "getUserData";
  const body = {
    address: address,
    username: username,
    userId: userId,
  };

  return await bondApiCall(path, body, jwt);
}

async function getProductData({ productId, jwt }) {
  const body = {
    productId: productId,
  };
  return await bondApiCall("getProductData", body, jwt);
}

async function updateUserData({ user, jwt }) {
  const body = {
    ...user,
  };
  const response = await bondApiCall("updateUserData", body, jwt);
  return response;
}

async function createOrder({ productId, bondId, jwt }) {
  const body = {
    productId,
    bondId,
  };
  const response = await bondApiCall("createOrder", body, jwt);
  return response;
}

async function getAltcoinsBalance({ address }) {
  const body = {
    address: address
  };
  const response = await bondApiCall("getAltcoinsBalance", body, undefined);
  return response;
}

async function getTemporaryFBToken() {
  const body = {
    
  };
  const response = await bondApiCall("getTemporaryFBToken", body, undefined);
  return response;
}

async function submitMaxPhoto({ maxPhotoUrl, twitterUrl, address }) {
  const body = {
    maxPhotoUrl, 
    twitterUrl, 
    address
  };
  const response = await bondApiCall("submitMaxPhoto", body, undefined);
  return response;
}


export const bondAPI = {
  login,
  createLogin,
  getProductData,
  getUserData,
  updateUserData,
  createOrder,
  getAltcoinsBalance,
  getTemporaryFBToken,
  submitMaxPhoto
};
