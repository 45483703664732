import { useContext, useEffect, useState } from "react";
import ethereumUtils from "../utils/ethereum";
import utils from "../utils/utils";
import { SizedBox } from "./SizedBox";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Web3Context } from "../contexts/Web3Context";
import specialSymbols from "../utils/specialSymbols";

const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;

const RuleViewWrapper = styled.div`
  width: ${isMobile ? "100%" : "45%"};
  display: flex;
  justify-conent: start;
  margin-top: 6px;
  margin-bottom: 6px;
`;

export default function RuleView({ rule, provider, isMobile }) {
  switch (rule.type) {
    case "nft-ownership":
      return (
        <RuleViewWrapper isMobile={isMobile}>
          <div>
            <NFTOwnershipRuleView rule={rule} provider={provider} />
          </div>
        </RuleViewWrapper>
      );
      case "nft-ownership-consumable":
        return (
          <RuleViewWrapper isMobile={isMobile}>
            <div>
              <NFTOwnershipRuleViewConsumable rule={rule} provider={provider} />
            </div>
          </RuleViewWrapper>
        );
    case "erc20-ownership":
      return (
        <RuleViewWrapper isMobile={isMobile}>
          <div>
            <ERC20OwnershipRuleView rule={rule} provider={provider} />
          </div>
        </RuleViewWrapper>
      );
    case "eth-ownership":
      return (
        <RuleViewWrapper isMobile={isMobile}>
          <div>
            <ETHOwnershipRuleView rule={rule} provider={provider} />
          </div>
        </RuleViewWrapper>
      );
    case "eth-payment":
        return (
          <RuleViewWrapper isMobile={isMobile}>
            <div>
              <ETHPaymentRuleView rule={rule} provider={provider} />
            </div>
          </RuleViewWrapper>
        );
    default:
      return <div></div>;
  }
}

function NFTOwnershipRuleView({ rule, provider }) {
  const { showSnackbar } = useContext(Web3Context);
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }
    console.log(ethereumUtils)
    const tokenDataResp = await ethereumUtils.getNFTContractData({
      nftAddress: rule.tokenAddress,
      provider: provider,
      needImage: true,
    });
    if (tokenDataResp.error) {
      showSnackbar({ message: tokenDataResp.error, severity: "error" });

      return;
    }
    const t = utils.getDefaultNFTTokenData({
      address: rule.tokenAddress,
    });
    if (t) {
      tokenDataResp.image = t.token.logoURI;
      tokenDataResp.logoURI = t.token.logoURI;
    }

    setTokenData(tokenDataResp);
    return;
  }
  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <>
      {tokenData && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <div style={{ width: "10%" }}></div>

          {tokenData && tokenData.image && (
            <img
              src={utils.formatIPFStoGateway(tokenData.image)}
              alt={"logo"}
              style={{
                borderRadius: "500px",
                height: "30px",
                width: "30px",
              }}
            />
          )}
          <SizedBox width={8} />

          <div style={{ color:"black" }}>{rule.type.includes("own") ? "Own: " : "Pay: "}</div>
          <SizedBox width={8} />

          <div style={{ whiteSpace: "nowrap", color:"black"  }}>{rule.quantity}</div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <SizedBox width={8} />
            <div style={{color: "black"}}>
              {specialSymbols.data[rule.tokenAddress.toLowerCase()] &&
              specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                ? specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                : tokenData.symbol}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function NFTOwnershipRuleViewConsumable({ rule, provider }) {
    const { showSnackbar } = useContext(Web3Context);
    const [tokenData, setTokenData] = useState(undefined);
    console.log(rule)
    async function getInitialData() {
      if (!rule) {
        return;
      }
      if (rule.tokenAddress.length !== testAddyLength) {
        return;
      }
      console.log(ethereumUtils)

      const tokenDataResp = await ethereumUtils.getNFTContractData({
        nftAddress: rule.tokenAddress,
        provider: provider,
        needImage: true,
      });
      if (tokenDataResp.error) {
        showSnackbar({ message: tokenDataResp.error, severity: "error" });
  
        return;
      }
      const t = utils.getDefaultNFTTokenData({
        address: rule.tokenAddress,
      });
      if (t) {
        tokenDataResp.image = t.token.logoURI;
        tokenDataResp.logoURI = t.token.logoURI;
      }
  
      setTokenData(tokenDataResp);
      return;
    }
    useEffect(() => {
      getInitialData();
    }, []);
    return (
      <>
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <div style={{ width: "10%" }}></div>
  
            {tokenData && tokenData.image && (
              <img
                src={utils.formatIPFStoGateway(tokenData.image)}
                alt={"logo"}
                style={{
                  borderRadius: "500px",
                  height: "30px",
                  width: "30px",
                }}
              />
            )}
            <SizedBox width={8} />
  
            <div style={{ color:"black" }}>{rule.type.includes("own") ? "Own: " : "Pay: "}</div>
            <SizedBox width={8} />
  
            <div style={{ whiteSpace: "nowrap" , color:"black"  }}>{rule.quantity}</div>
  
            <div style={{ display: "flex", alignItems: "center" }}>
              <SizedBox width={8} />
              <div style={{color: "black"}}>
                {specialSymbols.data[rule.tokenAddress.toLowerCase()] &&
                specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  ? specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  : tokenData.symbol}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  

function ERC20OwnershipRuleView({ rule, provider }) {
  const { showSnackbar } = useContext(Web3Context);
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }
    const tokenDataResp = await ethereumUtils.getERC20ContractData({
      address: rule.tokenAddress,
      provider: provider,
      needImage: true,
    });
    if (tokenDataResp.error) {
      showSnackbar({ message: tokenDataResp.error, severity: "error" });
      return;
    }
    const t = utils.getUniswapTokenData({
      address: rule.tokenAddress,
    });
    if (t) {
      tokenDataResp.image = t.token.logoURI;
      tokenDataResp.logoURI = t.token.logoURI;
    }
    setTokenData(tokenDataResp);
    return;
  }
  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <div>
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start ",
            }}
          >
            <div style={{ width: "10%" }}></div>

            {tokenData && tokenData.image && (
              <img
                src={utils.formatIPFStoGateway(tokenData.image)}
                alt={"logo"}
                style={{
                  borderRadius: "500px",
                  height: "30px",
                  width: "30px",
                }}
              />
            )}
            <SizedBox width={8} />

            <div>{rule.type.includes("own") ? "Own: " : "Pay: "}</div>
            <SizedBox width={8} />

            <div style={{ whiteSpace: "nowrap", color:"black"  }}>
              {rule.quantity / 10 ** parseInt(tokenData.decimals)}
            </div>

            <div style={{ display: "flex", alignItems: "center", color:"black" }}>
              <SizedBox width={8} />
              <div style={{color:"black"}}>
                {specialSymbols.data[rule.tokenAddress.toLowerCase()] &&
                specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  ? specialSymbols.data[rule.tokenAddress.toLowerCase()].symbol
                  : tokenData.symbol}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function ETHOwnershipRuleView({ rule, provider }) {
  const [tokenData, setTokenData] = useState(undefined);
  async function getInitialData() {
    if (!rule) {
      return;
    }
    if (rule.tokenAddress.length !== testAddyLength) {
      return;
    }

    const tokenDataResp = {
      address: rule.address,
      symbol: rule.symbol,
      name: rule.name,
      image: rule.image,
      logoURI: rule.image,
      decimals: rule.decimals,
    };

    setTokenData(tokenDataResp);
    return;
  }
  useEffect(() => {
    getInitialData();
  }, []);
  return (
    <>
      <div>
        {tokenData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start ",
            }}
          >
            <div style={{ width: "10%" }}></div>

            {tokenData && tokenData.image && (
              <img
                src={utils.formatIPFStoGateway(tokenData.image)}
                alt={"logo"}
                style={{
                  borderRadius: "500px",
                  height: "30px",
                  width: "30px",
                }}
              />
            )}
            <SizedBox width={8} />

            <div>{rule.type.includes("own") ? "Own: " : "Pay: "}</div>
            <SizedBox width={8} />

            <div style={{ whiteSpace: "nowrap" }}>
              {rule.quantity / 10 ** parseInt(tokenData.decimals)}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <SizedBox width={8} />
              <div>{tokenData.symbol}</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function ETHPaymentRuleView({ rule, provider }) {
    const [tokenData, setTokenData] = useState(undefined);
    async function getInitialData() {
      if (!rule) {
        return;
      }
      if (rule.tokenAddress.length !== testAddyLength) {
        return;
      }
  
      const tokenDataResp = {
        address: rule.address,
        symbol: rule.symbol,
        name: rule.name,
        image: rule.image,
        logoURI: rule.image,
        decimals: rule.decimals,
      };
  
      setTokenData(tokenDataResp);
      return;
    }
    useEffect(() => {
      getInitialData();
    }, []);
    return (
      <>
        <div>
          {tokenData && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start ",
              }}
            >
              <div style={{ width: "10%" }}></div>
  
              {tokenData && tokenData.image && (
                <img
                  src={utils.formatIPFStoGateway(tokenData.image)}
                  alt={"logo"}
                  style={{
                    borderRadius: "500px",
                    height: "30px",
                    width: "30px",
                  }}
                />
              )}
              <SizedBox width={8} />
  
              <div style={{color:"black"}}>{rule.type.includes("own") ? "Own: " : "Pay: "}</div>
              <SizedBox width={8} />
  
              <div style={{ whiteSpace: "nowrap", color: "black" }}>
                {rule.quantity / 10 ** parseInt(tokenData.decimals)}
              </div>
  
              <div style={{ display: "flex", alignItems: "center", color: "black" }}>
                <SizedBox width={8} />
                <div>{tokenData.symbol}</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
