// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import { v4 as uuidv4 } from "uuid";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsncTGEj7miWSja9EMrakZ4q5aFDex41k",
  authDomain: "scedule-bond.firebaseapp.com",
  projectId: "scedule-bond",
  storageBucket: "scedule-bond.appspot.com",
  messagingSenderId: "759975417668",
  appId: "1:759975417668:web:fa40fcf29034e2ef80a911",
  measurementId: "G-P1JPER2YHE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const analytics = getAnalytics(app);
const auth = getAuth();

function generateUserAvatarMediaPath(userId) {
  return `/avatars/${userId}/`;
}


function getMaxPaths() {
  return `/stalk-max/`;
}


async function updateAvatarImage(imageToUpload, userId) {
  try {
    const imagePath = generateUserAvatarMediaPath(userId);
    const imageFileName = imagePath + uuidv4();
    const firebaseStorageRef = ref(storage, imageFileName);
    const response = await uploadBytes(firebaseStorageRef, imageToUpload);
    const downloadUrl = await getDownloadURL(response.ref);
    return { downloadUrl: downloadUrl };
  } catch (e) {
    return { error: e };
  }
}

async function uploadMax(imageToUpload) {
  try {
    const imagePath = getMaxPaths();
    const imageFileName = imagePath + uuidv4();
    const firebaseStorageRef = ref(storage, imageFileName);
    const response = await uploadBytes(firebaseStorageRef, imageToUpload);
    const downloadUrl = await getDownloadURL(response.ref);
    return { downloadUrl: downloadUrl };
  } catch (e) {
    return { error: e };
  }
}

async function signinWithFirebaseToken(jwt) {
  const response = await signInWithCustomToken(auth, jwt);
}

async function log({ eventName, eventParams }) {
  logEvent(analytics, eventName, eventParams);
}

const firebaseUtils = {
  app,
  storage,
  updateAvatarImage,
  signinWithFirebaseToken,
  log,
  getMaxPaths,
  uploadMax
};
export default firebaseUtils;
