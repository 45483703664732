import ky from "ky";

const SmartySDK = require("smartystreets-javascript-sdk");
const { default: config } = require("./config");
const SmartyCore = SmartySDK.core;
const Lookup = SmartySDK.usAutocompletePro.Lookup;
const IntLookup = SmartySDK.internationalStreet.Lookup;

let key = config.smartyStreetsWebsiteKey;
const credentials = new SmartyCore.SharedCredentials(key);

let clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
  "us-autocomplete-pro-cloud",
]);

let intClientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses([
  "international-autocomplete-cloud",
]);
// .withBaseUrl("");
let client = clientBuilder.buildUsAutocompleteProClient();
let internationalClient = intClientBuilder.buildInternationalStreetClient();

const internationalStreetApiUri =
  "https://international-street.api.smartystreets.com/verify";
const usAutoCompleteApiUrl =
  "https://us-autocomplete.api.smartystreets.com/suggest";
const usAutoCompleteProApiUrl =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup";
const usExtractApiUrl = "https://us-extract.api.smartystreets.com/";
const usStreetApiUrl = "https://us-street.api.smartystreets.com/street-address";
const usZipCodeApiUrl = "https://us-zipcode.api.smartystreets.com/lookup";
const usReverseGeoApiUrl =
  "https://us-reverse-geo.api.smartystreets.com/lookup";

const referer = config.smartyStreetsReferrer;

async function handleLookup(search) {
  try {
    const lookup = new Lookup(search);
    const results = await client.send(lookup);
    return results;
  } catch (err) {
    return { error: err };
  }
}

async function handleInternationalLookupV2(country, search) {
  try {
    const results = await ky(
      `https://international-autocomplete.api.smartystreets.com/lookup?key=${key}&country=${country}&search=${encodeURIComponent(
        search
      )}`,
      {
        headers: {
          referer: referer,
        },
      }
    ).json();
    return results;
  } catch (e) {
    console.log(e);
    return { error: e };
  }
}

async function handleInternationalLookup(country, search) {
  try {
    console.log("country: ", country);
    console.log("search: ", search);
    const lookup = new IntLookup(country, search);
    console.log("lookup: ", lookup);
    const results = await internationalClient.send(lookup);
    console.log(results);
    return results;
  } catch (e) {
    console.log(e);
    return { error: e };
  }
}

const smartystreets = {
  handleLookup: handleLookup,
  handleInternationalLookup: handleInternationalLookup,
  handleInternationalLookupV2: handleInternationalLookupV2,
};

export default smartystreets;
