import { ethers } from "ethers";
import { useState, useEffect } from "react";
import "./App.css";
import Mint from "./pages/Mint";
import "bootstrap/dist/css/bootstrap.css";
import { Web3Context } from "./contexts/Web3Context";
import MediumButton from "./components/MediumButton";
import ConnectWalletButton from "./components/ConnectWalletButton";
import AltCoins from "./backend/abis/AltCoins.json";
import { Center } from "./components/Center";
import firebaseUtils from "./utils/firebase";
import Checks from "./pages/Checks"

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import SudoSwap from "./pages/SudoSwap";
import Swag from "./pages/Swag";
import BondSnackbar from "./components/BondSnackbar";
import { bondAPI } from "./utils/api";
import localstorage from "./utils/localstorage";
import Buy from "./pages/Buy";
import StalkMax from "./pages/StalkMax";
const networkAddresses = {
  1: "0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a",
  // 4: "0x8Ab7B09a45E1E71Ae9004AC48E1c950CF5343992"
};

function App() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(undefined);
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [networkId, setNetworkId] = useState(undefined);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [altCoins, setAltCoins] = useState(undefined);
  const [price, setPrice] = useState(undefined);
  const [whitelistPrice, setWhitelistPrice] = useState(undefined);
  const [balanceOf, setBalanceOf] = useState(undefined);
  const [whitelistAmount, setWhitelistAmount] = useState(undefined);
  const [paused, setPaused] = useState(undefined);
  const [isWhitelistActive, setIsWhitelistActive] = useState(undefined);
  const [totalAmount, setTotalAmount] = useState(undefined);
  const [totalSupply, setTotalSupply] = useState(undefined);
  const [publicAmount, setPublicAmount] = useState(undefined);
  const [mintCount, setMintCount] = useState(undefined);
  const [isMinting, setIsMinting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [signer, setSigner] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const [jwt, setJwt] = useState(undefined);
  const [showGlobalSnackbar, setShowGlobalSnackbar] = useState(false);
  const [globalSnackbarSeverity, setGlobalSnackbarSeverity] =
    useState("success");
  const [globalSnackbarMessage, setGlobalSnackbarMessage] = useState("");
  const [error, setError] = useState("");

  const logout = async () => {
    
    localstorage.setLocalStorageJWT(undefined);
    localstorage.setLocalStorageUser(undefined);
    setUser(null);
    setJwt(null);
    setAccount(null);
  };

  const loadBlockchainData = async () => {
    if (provider) {
      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1) {
        console.log("Please switch to the Ethereum Network");
      }
      try {
        const _altCoins = new ethers.Contract(
          networkAddresses[chainId],
          AltCoins.abi,
          signer ? signer : provider
        );
        setBlockchainData(_altCoins);
      } catch (e) {}
      // const networkId
    }
  };

  const loadWeb3 = async () => {
    if (typeof window.ethereum !== "undefined" && !account) {
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(_provider);
      const { chainId } = await _provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1) {
        console.log("Not on Ethereum");
      }
      const _account = await _provider.getSigner();
      setSigner(_account);
      if (_account && _account.getAddress) {
        const a = await _account.getAddress();
        if (a !== account) {
          setAccount(a);
        }
      } else {
        console.log("Log in with metamask");
      }

      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0] !== account) {
          const w = _provider.getSigner();
          setSigner(w);
          setAccount(accounts[0]);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  };

  async function setBlockchainData(_altCoins) {
    setAltCoins(_altCoins);
    if (!_altCoins) {
      return;
    }
    const [ta, ts, wp, p, pau, iwla, pub] = await Promise.all([
      _altCoins.totalAmount(),
      _altCoins.totalSupply(),
      _altCoins.whitelistPrice(),
      _altCoins.price(),
      _altCoins.paused(),
      _altCoins.isWhitelistActive(),
      _altCoins.publicAmount(),
    ]);

    setTotalAmount(ta);
    setTotalSupply(ts);
    setWhitelistPrice(wp);
    setPrice(p);
    setPaused(pau);
    setIsWhitelistActive(iwla);
    setPublicAmount(pub);

    if (account) {
      const [w, b, mc] = await Promise.all([
        _altCoins.whitelist(account),
        _altCoins.balanceOf(account),
        _altCoins.mintCount(account),
      ]);

      setWhitelistAmount(w);
      setBalanceOf(b);
      setMintCount(mc);
    }
  }

  const web3Handler = async () => {
    if (provider) {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const _account = await provider.getSigner();
      setSigner(_account);
      const a = await _account.getAddress();
      if (a !== account) {
        setAccount(a);
      }
    }
  };

  async function getInitialData() {
    try {
      let response;
      const j = localstorage.getLocalStorageJwt();
      const t = localstorage.getLocalStorageTheme();

      if (!j) {
        console.log("no jwt");
        // response = await api.getSharedInfo();
        // if (response.error) {
        //   window.alert(response.error);
        //   return;
        // }

        return;
      }
      setIsLoggingIn(true);
      response = await bondAPI.getUserData({ jwt: j, userId: undefined });
      if (!response || !response.user) {
        throw new Error("Please login again");
      }

      if (response.error) {
        setUser(undefined);
        setJwt(undefined);
        localstorage.setLocalStorageJWT(undefined);
        localstorage.setLocalStorageUser(undefined);
        firebaseUtils.log({
          eventName: "getUserData error",
          eventParams: { error: response.error },
        });

        throw new Error(response.error);
        // response = await api.getSharedInfo();W
        return;
      }

      setUser(response.user);
      setJwt(j);
      firebaseUtils.log({
        eventName: "getInitialData getUserData",
        eventParams: { uid: response.user._id },
      });
      // response = await api.getSharedInfo();
      setIsLoggingIn(false);
    } catch (e) {
      console.log("InitialData Error: ", e);
      setUser(undefined);
      setJwt(undefined);
      localstorage.setLocalStorageJWT(undefined);
      localstorage.setLocalStorageUser(undefined);
      setIsLoggingIn(false);
      try {
        firebaseUtils.log({
          eventName: "error",
          eventParams: { error: e },
        });
      } catch (e) {}
      showSnackbar({ message: e, severity: "error" });
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (error === "Please Login Again") {
      logout();
    }
  }, [error]);

  useEffect(() => {
    loadWeb3();
    loadBlockchainData();
  }, [account]);

  const mintNFTHandler = async (quantity) => {
    // Mint NFT
    try {
      if (paused) {
        return;
      }
      if (altCoins) {
        setIsMinting(true);
        setIsError(false);
        let p;

        if (isWhitelistActive) {
          p = whitelistPrice;
        } else {
          p = price;
        }

        const tx = await altCoins.mint(quantity, {
          from: account,
          value: (p.toNumber() * quantity).toString(),
        });
        const receipt = await tx.wait();
        await setBlockchainData(altCoins);

        //  await altCoins.mint(quantity, { from: account, value: (p.toNumber()* quantity).toString()  })
        // .on('receipt', async () => {
        // 	try {
        // 		await setBlockchainData(altCoins)
        // 	} catch (e) {
        // 	}
        // })

        // .on('error', (error) => {
        //   const possibleErrors =
        //    {
        //     "Contract is paused": "ERROR: Contract is paused",
        //     "You cannot mint more than the total amount": "ERROR: You cannot buy more than the remaining supply",
        //     "You cannot mint that many": "Error: You cannot mint that many",
        //     "I need more dough": "ERROR: Cough up more dough, that ain't enough!"
        //   }

        //   let errorMessage
        //   for(const p in possibleErrors) {
        //     if(error.message.includes(p)){
        //       errorMessage = possibleErrors[p]
        //     }
        //   }
        // 	window.alert(errorMessage ? errorMessage : "ERROR: Something went wrong...")
        // 	setIsError(true)
        // })
      }

      setIsMinting(false);
    } catch (e) {
      const possibleErrors = {
        "Contract is paused": "ERROR: Contract is paused",
        "You cannot mint more than the total amount":
          "ERROR: You cannot buy more than the remaining supply",
        "You cannot mint that many": "Error: You cannot mint that many",
        "I need more dough": "ERROR: Cough up more dough, that ain't enough!",
        "insufficient funds for intrinsic transaction cost":
          "You're too broke to mint that many!",
      };

      let errorMessage;
      for (const p in possibleErrors) {
        if (e.message.includes(p)) {
          errorMessage = possibleErrors[p];
        }
      }
      window.alert(
        errorMessage ? errorMessage : "ERROR: Something went wrong..."
      );
      setIsMinting(false);
    }
  };

  function showSnackbar({ message, severity }) {
    window.alert(message);
    // setGlobalSnackbarMessage(message);
    // setGlobalSnackbarSeverity(severity);
    // setShowGlobalSnackbar(true);
    if (severity === "error") {
      setError(message);
    }
  }

  function getMessage({ loginUid }) {
    return `
    Welcome to AltCoins.gg!
    
    By signing you agree to Milian Digital, LLC's Privacy Policy and Terms of Service.
    
    nonce: ${loginUid}
    `;
  }

  useEffect(() => {
    if (user) {
      localstorage.setLocalStorageUser(user);
    }
  }, [user]);

  useEffect(() => {
    if (jwt) {
      localstorage.setLocalStorageJWT(jwt);
    }
  }, [jwt]);

  const login = async () => {
    if (provider) {
      // await web3Handler();
      if (!window.ethereum) {
        throw new Error("No crypto wallet found. Please install it.");
      }

      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
        return;
      }
      setIsLoggingIn(true);
      const loginResp = await bondAPI.createLogin();
      setIsLoggingIn(false);

      if (loginResp.error) {
        showSnackbar({ message: loginResp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "createLogin error",
          eventParams: { error: loginResp.error },
        });
        return;
      }
      firebaseUtils.log({
        eventName: "createLogin",
        eventParams: { uid: loginResp.login._id },
      });
      await window.ethereum.send("eth_requestAccounts");
      let _provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = _provider.getSigner();
      setSigner(signer)
      const message = getMessage({ loginUid: loginResp.login._id });
      const signature = await _signer.signMessage(message);
      setIsLoggingIn(true);

      const resp = await bondAPI.login({
        message: message,
        signature: signature,
      });
      if (resp.error) {
        setUser(undefined);
        setJwt(undefined);
        setIsLoading(false);
        setIsLoggingIn(false);
        showSnackbar({ message: resp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "login error",
          eventParams: { error: resp.error },
        });
        return;
      }

      setUser(resp.user);
      setJwt(resp.token);
      await firebaseUtils.signinWithFirebaseToken(resp.firebaseToken);
      if (_signer && _signer.getAddress) {
        const a = await _signer.getAddress();
        setAccount(a);
      }
      if (signer && signer.getAddress) {
        const a = await signer.getAddress();
        setAccount(a);
      }
      firebaseUtils.log({
        eventName: "login",
        eventParams: { uid: resp.user._id },
      });

      setIsLoggingIn(false);

      return;
    } else {
      setIsLoggingIn(false);
      showSnackbar({
        message: "Please Connect an Ethereum Wallet via Metamask",
        severity: "error",
      });
    }
  };

  useEffect(() => {
    loadBlockchainData(false);

    if (account) {
      // login();
    } else {
      loadWeb3();
    }
  }, [account]);

  return (
    <div className="App">
      <Web3Context.Provider
        value={{
          provider,
          account,
          signer,
          networkId,
          setNetworkId,
          web3Handler,
          altCoins,
          paused,
          isWhitelistActive,
          whitelistAmount,
          whitelistPrice,
          price,
          totalAmount,
          totalSupply,
          balanceOf,
          publicAmount,
          mintCount,
          mintNFTHandler,
          isMinting,
          user,
          setUser,
          jwt,
          setJwt,
          login,
          logout,
          showSnackbar,
          log: firebaseUtils.log,
        }}
      >
        <Center>
          <ConnectWalletButton />
        </Center>
        <Routes>
          <Route path="/sudo" element={<SudoSwap />} />
          <Route path="/drip/buy" element={<Buy />} />
          <Route path="/drip" element={<Swag />} />
          <Route path="/stalk-max" element={<StalkMax />} />
          <Route path="/" element={<Mint />} />
        </Routes>
        <BondSnackbar
          open={showGlobalSnackbar}
          setOpen={setShowGlobalSnackbar}
          message={globalSnackbarMessage}
          severity={globalSnackbarSeverity}
        />
      </Web3Context.Provider>
    </div>
  );
}

function MyApp() {
  return (
    <div id={"app"} className="app2d">
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default MyApp;
