import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { bondAPI } from "../utils/api";
import { Web3Context } from "../contexts/Web3Context";
import { LoadingIndicator } from "./LoadingIndicator";
import { VariantSelector } from "./VariantSelector";
import { Col } from "react-bootstrap";

function Shirt({ myRef, shirtUrl }) {
  const gltf = useLoader(GLTFLoader, shirtUrl);
  return (
    <primitive object={gltf.scene} ref={myRef} position={[0, -1.1, 10.2]} />
  );
}

function FreeShirtMod({ shirtUrl }) {
  const shirt = React.useRef();

  useFrame(({ clock }) => {
    if (!shirt || !shirt.current) {
      return;
    }
    const a = clock.getElapsedTime();
    shirt.current.rotation.y = a;
  });

  return (
    <>
      <ambientLight intensity={0.5} />
      <spotLight intensity={0.8} position={[0, 0, 12]} />
      <Suspense fallback={LoadingIndicator}>
        {" "}
        <Shirt myRef={shirt} shirtUrl={shirtUrl} />
      </Suspense>
    </>
  );
}

export default function FreeShirt({ shirt }) {
  return (
    <>
      <Canvas
        style={{ width: "100%", height: "460px" }}
        camera={{ position: [0, 0.2, 11] }}
      >
        <FreeShirtMod shirtUrl={shirt.product.modelUrl} />
      </Canvas>
    </>
  );
}
