import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Web3Context } from "../contexts/Web3Context";
import {
  AddressDetailsCard,
  AddressDetailsSubtitle,
  AddressDetailsTitle,
} from "./RequireShippingModal";
import { SizedBox } from "./SizedBox";
import { SubtitleText } from "./SubtitleText";

export function AddressPreview() {
  const { user } = useContext(Web3Context);
  return (
    <>
      {user && user.shippingAddress && (
        <Col>
          <Row>
            <Col>
              <AddressDetailsCard>
                <Col className="d-flex flex-column align-items-center">
                  <Row
                    style={{ width: "100%" }}
                    className="d-flex justify-content-end align-items-end"
                  ></Row>
                  <SubtitleText color="black">Your address</SubtitleText>
                  <SizedBox height="8px" />
                  <FaMapMarkerAlt color="black" size={20} />
                  <SizedBox height="8px" />
                  <AddressDetailsTitle>
                    {user.shippingAddress.address_line1}
                  </AddressDetailsTitle>
                  <AddressDetailsSubtitle>
                    {user.shippingAddress.address_line2}
                  </AddressDetailsSubtitle>

                  <AddressDetailsSubtitle>{`${user.shippingAddress.city}, ${user.shippingAddress.state}, USA`}</AddressDetailsSubtitle>
                </Col>
              </AddressDetailsCard>
            </Col>
          </Row>
        </Col>
      )}

      {user && user.intShippingAddress && (
        <Col>
          <Row>
            <Col>
              <AddressDetailsCard>
                <Col className="d-flex flex-column align-items-center">
                  <Row
                    style={{ width: "100%" }}
                    className="d-flex justify-content-end align-items-end"
                  ></Row>
                  <SubtitleText color="black">Your address</SubtitleText>
                  <SizedBox height="8px" />
                  <FaMapMarkerAlt color="black" size={20} />
                  <SizedBox height="8px" />
                  <AddressDetailsTitle>
                    {user.intShippingAddress.address1}
                  </AddressDetailsTitle>
                  <AddressDetailsSubtitle>
                    {user.intShippingAddress.address2}
                  </AddressDetailsSubtitle>

                  <AddressDetailsSubtitle>{`${user.intShippingAddress.locality}, ${user.intShippingAddress.administrative_area}, ${user.intShippingAddress.country_iso_3}`}</AddressDetailsSubtitle>
                </Col>
              </AddressDetailsCard>
            </Col>
          </Row>
        </Col>
      )}
    </>
  );
}
