import React from "react";
import styled from "styled-components";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
const ShippingAddressTileWrapper = styled.div`
  width: 100%;
  padding: 5%;

  border-radius: 24px;
  cursor: pointer;
  background-color: white;
`;
//    border: 1px solid #F0F0F0;

const AddressTitle = styled.div`
  color: ${"black"};
  font-weight: bold;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const AddressSubtitle = styled.div`
  color: ${"black"};
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ShippingAddressTile = ({ address, country, onClick }) => {
  return (
    <ShippingAddressTileWrapper onClick={(e) => onClick(address)}>
      <>
        {country && country.value === "USA" ? (
          <Row className="d-flex align-items-center">
            <FaMapMarkerAlt style={{ width: "auto", color: "black" }} />

            <Col>
              <AddressTitle>{address.streetLine}</AddressTitle>
              <AddressSubtitle>{`${address.city}, ${address.state}, USA`}</AddressSubtitle>
            </Col>
          </Row>
        ) : (
          <Row className="d-flex align-items-center">
            <FaMapMarkerAlt style={{ width: "auto", color: "black" }} />

            <Col>
              <AddressTitle>{address.street}</AddressTitle>
              <AddressSubtitle>{`${address.locality}, ${address.administrative_area}, ${country.value}`}</AddressSubtitle>
            </Col>
          </Row>
        )}
      </>
    </ShippingAddressTileWrapper>
  );
};
