import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import DescriptionText from "../components/DescriptionText";
import { Col, Row } from "react-bootstrap";
import { Center } from "../components/Center";
import { SizedBox } from "../components/SizedBox";
import SpinningCoinRGB from "../components/SpinningCoinRGB";
import MediumButton from "../components/MediumButton";
import { CustomTextField } from "../components/CustomTextField";
import { Web3Context } from "../contexts/Web3Context";



export default function SudoSwap() {
    const { altCoins, signer, account, paused, isWhitelistActive, mintNFTHandler, whitelistAmount, mintCount, publicAmount, totalAmount, totalSupply, isMinting } = useContext(Web3Context)
    const [quantity, setQuantity] = useState("")
    const [maxMint, setMaxMint] = useState(0)

    async function getInitialData(){
        
    }

    return <div>
        <Center>
            <div style={{ width: "30%" }}>
                <Row>
                    <Col>
                        <SizedBox height={32} />
                        <Center>
                            <DescriptionText>
                                Use <a href={"https://sudoswap.xyz/#/browse/buy/0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a"} target="_blank" rel="noopener noreferrer"
                                >SudoSwap</a> to get your AltCoins, regardless of the art!
                            </DescriptionText>
                        </Center>
                        <Row>
                            <div style={{ padding: "45px" }}>
                                <SpinningCoinRGB width={"67%"} />
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                {altCoins && signer && maxMint && maxMint.toString() !== "0" && <CustomTextField
                                    value={quantity}
                                    setValue={setQuantity}
                                    placeholder={`INPUT QUANTITY < MAX ${maxMint} >`}
                                    type={"number"}
                                    name={"ALTCOIN QUANTITY"}
                                />}
                                <MediumButton width={"100%"} text={"Buy on Sudo"} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Center>
    </div>
}