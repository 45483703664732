import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import styled, { css } from "styled-components";
import { Center } from "./Center";
import { SizedBox } from "./SizedBox";
import smartystreets from "../utils/smartystreets";
import { ShippingAddressTile } from "./ShippingAddressTile";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { LoadingIndicator } from "./LoadingIndicator";
import { useWindowDimensions } from "../utils/useWindowDimensions";
import { BrowserView, MobileView } from "react-device-detect";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Web3Context } from "../contexts/Web3Context";
import { bondAPI } from "../utils/api";
import MediumButton from "./MediumButton";
import { CustomTextField } from "./CustomTextField";
import { CountrySelector } from "./CountrySelector";

const ZoneButtonWrapper = styled.button`
  width: ${(props) => (props.width ? props.width : "30%")};
  height: "auto";
  background-color: ${(props) => (props.selected ? "black" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  font-weight: bold;
  font-size: 24px;
  padding: 20px 40px 20px 40px;
  border: 2px solid black;
`;

const AddressTitle = styled.div`
  color: ${"black"};
  margin-bottom: 12px;
  font-weight: bold;
  text-align: start;
  font-size: 24px;
`;

export const AddressDetailsCard = styled.div`
  width: 100%;
  border: 2px solid black;
  background-color: ${"white"};
  font-weight: bold;
  font-size: 16px;
  padding: 20px 40px 20px 40px;
`;

export const AddressDetailsTitle = styled.div`
  color: ${"black"};
  font-weight: bold;
  font-size: 20px;
`;

export const AddressDetailsSubtitle = styled.div`
  color: ${"black"};
  font-weight: bold;
  font-size: 16px;
`;

export const RequireShippingModal = ({ show, setShow }) => {
  const { width, height } = useWindowDimensions();
  return (
    <Fragment>
      <BrowserView>
        <Modal show={show} onHide={() => setShow(false)}>
          <RequireShipping show={show} setShow={setShow} />;
        </Modal>
      </BrowserView>
      <MobileView>
        <BottomSheet
          open={show}
          onDismiss={() => setShow(false)}
          maxHeight={Math.floor(height * 0.9)}
        >
          <RequireShipping show={show} setShow={setShow} />
        </BottomSheet>
      </MobileView>
    </Fragment>
  );
};
const RequireShipping = ({ show, setShow }) => {
  const { user, setUser, jwt } = useContext(Web3Context);
  const [search, setSearch] = useState("");
  const [apt, setApt] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tabKey, setTabKey] = useState("signup");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [clickClass, setClickClass] = useState("click-guard-blocked");
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState(undefined);
  const [address2, setAddress2] = useState(undefined);
  const [address3, setAddress3] = useState(undefined);
  const [validAddress, setValidAddress] = useState(false);
  const [isInternational, setIsInternational] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);

  useEffect(() => {
    setAddresses([]);
    setAddress(undefined);
  }, [selectedCountry]);

  useEffect(() => {
    if (!address && !selectedCountry) {
      setSelectedCountry({ label: "United States", value: "USA" });
    }
  }, [address]);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function validateAddress() {
    if (phoneNumber.length === 0) {
      setValidAddress(false);
      return;
    }
    if (firstName.length === 0) {
      setValidAddress(false);
      return;
    }

    if (lastName.length === 0) {
      setValidAddress(false);
      return;
    }
    setValidAddress(true);
  }

  useEffect(() => {
    validateAddress();
  }, [phoneNumber, firstName, lastName]);

  async function searchAddresses() {
    if (selectedCountry && selectedCountry.value === "USA") {
      const response = await smartystreets.handleLookup(search);
      if (response.result) {
        setAddresses(response.result);
      }
    } else {
      console.log(selectedCountry);
      // const response = await smartystreets.handleInternationalLookup(
      //   selectedCountry.value,
      //   search
      // );
      // if (response.result) {
      //   setAddresses(response.result);
      // }
      const response = await smartystreets.handleInternationalLookupV2(
        selectedCountry.value,
        search
      );
      console.log("Resposne: ", response);
      if (response.candidates) {
        setAddresses(response.candidates);
      }
    }
  }

  async function updateUser() {
    setIsLoading(true);
    if (!address) {
      window.alert("Select an Address");
      setIsLoading(false);
      return;
    }
    if (!validAddress) {
      window.alert("Input a valid address");
      setIsLoading(false);
      return;
    }
    try {
      let u = {};
      if (selectedCountry && selectedCountry.value === "USA") {
        const shippingAddress = {
          first_name: firstName,
          last_name: lastName,
          address_line1: address.streetLine,
          address_line2: apt,
          zip_code: address.zipcode,
          city: address.city,
          state: address.state,
          country: "USA",
          phone_number: phoneNumber,
        };
        u.shippingAddress = shippingAddress;
      } else {
        const intShippingAddress = {
          first_name: firstName,
          last_name: lastName,
          address1: address.street,
          address2: address2,
          address3: address3,
          postal_code: address.postal_code,
          administrative_area: address.administrative_area,
          locality: address.locality,
          country: selectedCountry.value,
          phone_number: phoneNumber,
        };
        u.intShippingAddress = intShippingAddress;
      }
      const response = await bondAPI.updateUserData({ user: u, jwt: jwt });
      if (response.error) {
        window.alert(response.error);
        setIsLoading(false);
        return;
      }
      setUser(response.user);
      setIsLoading(false);
      setShow(false);
    } catch (e) {
      window.alert(e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (search.length > 0) {
      searchAddresses();
    }
  }, [search]);

  useEffect(() => {
    if (user && user.shippingAddress) {
      setClickClass("click-guard-allowed");
      return;
    }

    setClickClass("click-guard-blocked");
  }, [user]);

  return (
    <div style={{ padding: 20 }}>
      {isLoading ? (
        <Center>
          <LoadingIndicator />
        </Center>
      ) : (
        <>
          <Row style={{ width: "100%" }}>
            <AddressTitle>Add your Shipping Address</AddressTitle>
          </Row>
          <CountrySelector
            country={selectedCountry}
            setCountry={setSelectedCountry}
          />
          {address ? (
            <AddressDetailsWidget
              address={address}
              setAddress={setAddress}
              apt={apt}
              setApt={setApt}
              address2={address2}
              setAddress2={setAddress2}
              address3={address3}
              setAddress3={setAddress3}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              validAddress={validAddress}
              updateUser={updateUser}
              country={selectedCountry}
            />
          ) : (
            <AddressSearchWidget
              search={search}
              setSearch={setSearch}
              setAddress={setAddress}
              addresses={addresses}
              country={selectedCountry}
            />
          )}
        </>
      )}
      <MediumButton text="Close" width="40%" onClick={handleClose} />
    </div>
  );
};

const AddressDetailsWidget = ({
  address,
  setAddress,
  apt,
  setApt,
  address2,
  setAddress2,
  address3,
  setAddress3,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  validAddress,
  updateUser,
  country,
}) => {
  return (
    <>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <SizedBox height={16} />
        <AddressDetailsCard>
          {country && country.value === "USA" ? (
            <Col className="d-flex flex-column align-items-center">
              <Row
                style={{ width: "100%" }}
                className="d-flex justify-content-end align-items-end"
              >
                <Col className="d-flex justify-content-end align-items-end">
                  <div>
                    <MdCancel
                      onClick={() => setAddress(undefined)}
                      style={{ color: "black", fontSize: "20px" }}
                    />{" "}
                  </div>
                </Col>
              </Row>
              <FaMapMarkerAlt />
              <AddressDetailsTitle>{address.streetLine}</AddressDetailsTitle>
              <AddressDetailsSubtitle>{`${address.city}, ${address.state}, USA`}</AddressDetailsSubtitle>
            </Col>
          ) : (
            <Col className="d-flex flex-column align-items-center">
              <Row
                style={{ width: "100%" }}
                className="d-flex justify-content-end align-items-end"
              >
                <Col className="d-flex justify-content-end align-items-end">
                  <div>
                    <MdCancel
                      onClick={() => setAddress(undefined)}
                      style={{ color: "black", fontSize: "20px" }}
                    />{" "}
                  </div>
                </Col>
              </Row>
              <FaMapMarkerAlt />
              <AddressDetailsTitle>{address.street}</AddressDetailsTitle>
              <AddressDetailsSubtitle>{`${address.locality}, ${address.administrative_area}, ${country.value}`}</AddressDetailsSubtitle>
            </Col>
          )}
        </AddressDetailsCard>
        <SizedBox height={18} />
        <>
          {country && country.value === "USA" ? (
            <>
              <Row style={{ width: "100%" }}>
                <CustomTextField
                  name="Apt/Suite"
                  value={apt}
                  setValue={setApt}
                  placeholder={"Type your Apt/Suite here"}
                />
              </Row>
            </>
          ) : (
            <>
              <Row style={{ width: "100%" }}>
                <CustomTextField
                  name="Address 2"
                  value={address2}
                  setValue={setAddress2}
                  placeholder={"Type your Address 2 here"}
                />
              </Row>
              <SizedBox height={8} />
              <Row style={{ width: "100%" }}>
                <CustomTextField
                  name="Address 3"
                  value={address3}
                  setValue={setAddress3}
                  placeholder={"Type your Address 3 here"}
                />
              </Row>
            </>
          )}
        </>
        <SizedBox height={8} />

        <Row style={{ width: "100%" }}>
          <Col>
            <CustomTextField
              name="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder={"First Name"}
            />
          </Col>
          <Col>
            <CustomTextField
              name="Last Name"
              value={lastName}
              setValue={setLastName}
              placeholder={"Last Name"}
            />
          </Col>
        </Row>
        <SizedBox height={8} />

        <Row style={{ width: "100%" }}>
          <CustomTextField
            name="Phone Number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder={"Type your Phone Number here"}
          />
        </Row>
        <SizedBox height={32} />
        <Row style={{ width: "100%" }}>
          <MediumButton
            width="100%"
            text={"Save Address"}
            onClick={updateUser}
            disabled={!validAddress}
          />
        </Row>
        <SizedBox height={18} />
      </Col>
    </>
  );
};

const InternationalAddressDetailsWidget = ({
  address,
  setAddress,
  apt,
  setApt,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  phoneNumber,
  setPhoneNumber,
  validAddress,
  updateUser,
}) => {
  return (
    <>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <SizedBox height={16} />
        <AddressDetailsCard>
          <Col className="d-flex flex-column align-items-center">
            <Row
              style={{ width: "100%" }}
              className="d-flex justify-content-end align-items-end"
            >
              <Col className="d-flex justify-content-end align-items-end">
                <div>
                  <MdCancel
                    onClick={() => setAddress(undefined)}
                    style={{ color: "black", fontSize: "20px" }}
                  />{" "}
                </div>
              </Col>
            </Row>
            <FaMapMarkerAlt color="black" />
            <AddressDetailsTitle>{address.streetLine}</AddressDetailsTitle>
            <AddressDetailsSubtitle>{`${address.city}, ${address.state}, USA`}</AddressDetailsSubtitle>
          </Col>
        </AddressDetailsCard>
        <SizedBox height={18} />
        <Row style={{ width: "100%" }}>
          <CustomTextField
            name="Apt/Suite"
            value={apt}
            setValue={setApt}
            placeholder={"Type your Apt/Suite here"}
          />
        </Row>
        <SizedBox height={8} />

        <Row style={{ width: "100%" }}>
          <Col>
            <CustomTextField
              name="First Name"
              value={firstName}
              setValue={setFirstName}
              placeholder={"First Name"}
            />
          </Col>
          <Col>
            <CustomTextField
              name="Last Name"
              value={lastName}
              setValue={setLastName}
              placeholder={"Last Name"}
            />
          </Col>
        </Row>
        <SizedBox height={8} />

        <Row style={{ width: "100%" }}>
          <CustomTextField
            name="Phone Number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder={"Type your Phone Number here"}
          />
        </Row>
        <SizedBox height={32} />
        <Row style={{ width: "100%" }}>
          <MediumButton
            width="100%"
            text={"Save Address"}
            onClick={updateUser}
            disabled={!validAddress}
          />
        </Row>
        <SizedBox height={18} />
      </Col>
    </>
  );
};

const AddressSearchWidget = ({
  addresses,
  setAddress,
  search,
  setSearch,
  country,
}) => {
  return (
    <>
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <SizedBox height={16} />

        <CustomTextField
          name="Shipping Address"
          placeholder="Start searching"
          value={search}
          setValue={setSearch}
        />
        <div style={{ height: "50vh", overflowY: "scroll", width: "100%" }}>
          <Row>
            <Col>
              {addresses.map((a) => (
                <ShippingAddressTile
                  country={country}
                  address={a}
                  onClick={setAddress}
                />
              ))}
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};
