import React from "react";


export default function BondSnackbar({ open, setOpen, severity, message }) {
  //severity
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEvent = () => {
    setOpen(true);
  };

  return <></>


}
