import React from "react";
import styled from "styled-components";

const DescriptionTextWrapper = styled.div`
    color: ${props => props.color};
`

export default function DescriptionText({children, color}){
    return <>
        <DescriptionTextWrapper color={color ? color : "white"}>
            {children}
        </DescriptionTextWrapper>
    </>
}