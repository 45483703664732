import { useEffect, useState } from "react";
import Select from "react-select";

export function createVariantOption(variant) {
  let label = "";
  let ovc = 0;
  for (const v of variant.optionValues) {
    if (ovc > 0) {
      label = label + " & ";
    }
    label = label + `${v.label}: ${v.value}`;
  }

  return {
    label: label,
    value: variant._id,
  };
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //   borderBottom: '1px dotted pink',
    color: "black",
    //   padding: 20,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    //   width: 200,
    ...provided,
    borderRadius: "0px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export function VariantSelector({ variants, setVariant }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let o = [];
    for (const v of variants) {
      o.push(createVariantOption(v.product));
    }
    setOptions(o);
  }, [variants]);
  return (
    <div>
      <Select
        options={options}
        onChange={(v) => setVariant({ variantId: v.value })}
        styles={customStyles}
      />
    </div>
  );
}
