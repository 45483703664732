import { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import styled from "styled-components";
import { Row } from "react-bootstrap";
import { TitleText } from "./TitleText";
import DescriptionText from "./DescriptionText";
import { SizedBox } from "./SizedBox";
import { BsFillCheckCircleFill } from "react-icons/bs";
import BondView from "./BondView";
import { SubtitleText } from "./SubtitleText";
import MediumButton from "./MediumButton";

const testAddy = "0xbb997742326E8Da7bfe63077f7F66aae07C9D676";
const testAddyLength = testAddy.length;

const BondsViewerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  border: 2px solid grey;
  border-radius: 20px;
  padding: 16px;
`;

export default function BondsViewer({
  bonds,
  otherUser,
  user,
  selectedBond,
  selectBond,
}) {
    console.log(bonds)
  const { provider, networkId } = useContext(Web3Context);
  return (
    <>
      {provider && networkId === 1 && (
        <>
          <Row className="d-flex">
            <TitleText color={"black"} fontSize="20px" textAlign={"start"}>Select the bond that you'd like to buy with</TitleText>
          </Row>
 
          {bonds.map((b, bondIndex) => (
            <div key={"bond-" + bondIndex + "bye"}>
              <>
                <SizedBox height={12} />
                {bondIndex === 0 ? (
                  <></>
                ) : (
                  <DescriptionText color={"black"} textAlign={"center"}>{`OR`}</DescriptionText>
                )}
              </>
              <SizedBox height={8} />

              <BondView
                bond={b}
                match={b.match}
                bondIndex={bondIndex}
                provider={provider}
                selected={selectedBond === bondIndex}
                selectBond={selectBond}
              />
            </div>
          ))}
        </>
      )}
      {(!provider || networkId !== 1) && (
        <>
          {!(typeof selectedBond === "undefined" && bonds.length > 0) ? (
            <div>
              <DescriptionText>
                {`In order to schedule a meeting with ${otherUser.username}, click the button below.`}
              </DescriptionText>
            </div>
          ) : (
            <div>
              <DescriptionText>
                {`In order to schedule a meeting with ${otherUser.username}, you have to connect an Ethereum Wallet`}
              </DescriptionText>
            </div>
          )}
        </>
      )}
      <SizedBox height={42} />
     
    </>
  );
}
