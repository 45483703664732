import ky from "ky";
import uniswap from "@uniswap/default-token-list";
import defaultNFTs from "./defaultNFTS.json";

const ipfsTell = "ipfs://";
const httpsTell = "https://";
const ipfsGateway = "https://collectees.mypinata.cloud/ipfs/";

function formatIPFStoGateway(ipfsURI) {
  if (!ipfsURI) {
    return undefined;
  }
  let isIPFS = ipfsURI.includes(ipfsTell);
  let isHTTPS = ipfsURI.includes(httpsTell);
  let newURI;
  if (isHTTPS) {
    return ipfsURI;
  }

  if (isIPFS) {
    newURI = ipfsURI.replace(ipfsTell, ipfsGateway);

    return newURI;
  }

  return undefined;
}

async function getNFTJson(ipfsURI) {
  if (!ipfsURI) {
    return undefined;
  }
  let isIPFS = ipfsURI.includes(ipfsTell);
  let isHTTPS = ipfsURI.includes(httpsTell);
  let newURI;
  if (isHTTPS) {
    newURI = ipfsURI;
  }

  if (isIPFS) {
    newURI = ipfsURI.replace(ipfsTell, ipfsGateway);
  }
  if (!newURI) {
    return;
  }
  const resp = await ky.get(newURI, { throwHttpErrors: false }).json();
  return resp;
}

function getUniswapTokenData({ address }) {
  for (const t of uniswap.tokens) {
    if (t.chainId === 1 && address === t.address) {
      //("token found");
      return { token: t };
    }
  }
}

function getDefaultNFTTokenData({ address }) {
  for (const t of defaultNFTs.tokens) {
    if (t.chainId === 1 && address === t.address) {
      //("token found");
      return { token: t };
    }
  }
}

const utils = {
  formatIPFStoGateway,
  getNFTJson,
  getUniswapTokenData,
  getDefaultNFTTokenData,
};

export default utils;
