const specialSymbols = {
    data: {
      // TO ADD A NEW SPECIAL SYMBOL
      "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb": {
        name: "CryptoPunks",
        symbol: "PUNK",
      },
    },
  };
  
  export default specialSymbols;
  