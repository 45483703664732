import React from 'react'
import styled, { css } from "styled-components";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const WidthContainerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: 100vh;
`;


const WidthContainerCenter = styled.div`
  width: ${props => props.desktopWidth ? props.desktopWidth : "45%"};

  @media (max-width: 1024px) {
    width: ${props => props.desktopWidth ? props.desktopWidth : "45%"};
  } 
`;

const MobileContainerCenter = styled.div`
width: ${props => props.mobileWidth ? props.mobileWidth : "90%"};
`;



export const WidthContainer= ({desktopWidth, mobileWidth, children}) => {
    return (
        <>
            <BrowserView>
                <WidthContainerWrapper>
                    <WidthContainerCenter desktopWidth={desktopWidth}>
                        {children}
                    </WidthContainerCenter>
                </WidthContainerWrapper>
            </BrowserView>
            <MobileView>
                <WidthContainerWrapper>
                    <MobileContainerCenter mobileWidth={mobileWidth}>
                        {children}
                    </MobileContainerCenter>
                </WidthContainerWrapper>
            </MobileView>
        </>
    )
}