import { useContext, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Web3Context } from "../contexts/Web3Context";
import { Center } from "./Center";
import IconButton from "./IconButton";
import { LoadingIndicator } from "./LoadingIndicator";
import { SizedBox } from "./SizedBox";
import { TitleText } from "./TitleText";
import { ImCross } from "react-icons/im";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import ConnectWalletButton from "./ConnectWalletButton";
import BondView from "./BondView";
import BondsViewer from "./BondsViewer";

export function BuyBondModal({ bonds, open, setOpen, selectedBond, selectBond }) {
    const { user, setUser, jwt, logout, account, showSnackbar, log } =
        useContext(Web3Context);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    return <>
        {true && (
            <>
                <BrowserView>
                    <Modal
                        dialogClassName="modal-60w"
                        show={open}
                        onHide={() => {
                            setOpen(false);
                        }}
                    >
                        <Modal.Body>
                            <Center>
                                {isLoading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <div style={{ width: "90%" }}>
                                        <SizedBox height={8} />
                                        <Row className="d-flex flex-row justify-content-center">
                                            <Col className="d-flex align-items-center">
                                                <TitleText fontSize={"26px"} textAlign={"start"} color={"black"}>
                                                    {!user ? "Login to continue" : "Select buy method"}

                                                </TitleText>
                                            </Col>
                                            <Col xs="2">
                                                <IconButton
                                                    Icon={ImCross}
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <SizedBox height={8} />
                                        <Row>
                                            <Col>
                                                {!user ? <>
                                                    <ConnectWalletButton />
                                                </> : <>
                                                    {
                                                        bonds && <BondsViewer bonds={bonds} otherUser={user} user={user} selectBond={selectBond} selectedBond={selectedBond} />
                                                    }
                                                </>}

                                            </Col>
                                        </Row>
                                        <SizedBox height={20} />

                                        <SizedBox height={48} />
                                    </div>
                                )}
                            </Center>
                        </Modal.Body>
                    </Modal>
                </BrowserView>
                <MobileView>
                    {account ? (
                        <>
                            <BottomSheet
                                open={open}
                                onDismiss={() => {
                                    setOpen(false);
                                }}
                            >
                                <Center>
                                    <div style={{ width: "90%" }}>
                                        <SizedBox height={8} />
                                        <Row className="d-flex flex-row justify-content-center">
                                            <Col className="d-flex align-items-center">
                                                <TitleText textAlign={"start"}>
                                                    {user ? "Login to continue" : "Select buy method"}
                                                </TitleText>
                                            </Col>
                                        </Row>
                                        <SizedBox height={20} />
                                        <Row>
                                            <Col>
                                                {!user ? <>
                                                    <ConnectWalletButton />
                                                </> : <>
                                                    {bonds && <BondsViewer bonds={bonds} otherUser={user} user={user} selectBond={selectBond} selectedBond={selectedBond} />
                                                    }
                                                </>}

                                            </Col>
                                        </Row>
                                    </div>
                                </Center>
                                <SizedBox height={32} />
                            </BottomSheet>
                        </>
                    ) : null}
                </MobileView>
            </>
        )}
    </>
}