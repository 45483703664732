import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FreeShirt from "../components/FreeShirt";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import { bondAPI } from "../utils/api";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { VariantSelector } from "../components/VariantSelector";
import MediumButton from "../components/MediumButton";
import "../components/Glitch.css";
import { BuyBondModal } from "../components/BuyBondModal";
import { useNavigate } from "react-router-dom";
import DescriptionText from "../components/DescriptionText";
import UploadAndDisplayImage from "../components/UploadAndDisplay";
import { SubtitleText } from "../components/SubtitleText";
import { CustomTextField } from "../components/CustomTextField";
import firebaseUtils from "../utils/firebase";
import SocialButton from "../components/SocialButton";


const sampleAddy = "0x420697b9d4Bb37A2eBEa7510501a8e4886711337"
export default function StalkMax() {
    const navigate = useNavigate();
    const [shirt, setShirt] = useState(undefined);
    const [selectedShirt, setSelectedShirt] = useState(undefined);
    const [shirtVariants, setShirtVariants] = useState(undefined);
    const [bonds, setBonds] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [buyBondOpen, setBuyBondOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(undefined)
    const [ethereumAddress, setEthereumAddress] = useState("")
    const [tweetUrl, setTweetUrl] = useState("")
    const { jwt, user, account, showSnackbar } = useContext(Web3Context);
    const [altcoinsBalance, setAltcoinsBalance] = useState(0)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    async function setEthAddy() {
        setEthereumAddress(account)
    }
    useEffect(() => {
        if (account) {
            setEthAddy()
        }
    }, [account])

    useEffect(() => {
        if (ethereumAddress.length === sampleAddy.length) {
            getAltcoinOwnership()
        }
    }, [ethereumAddress])

    async function getShirtProduct() {
        try {
            setIsLoading(true);
            const response = await bondAPI.getProductData({
                productId: "63065d485ae37ff57368c16e",
                jwt: jwt,
            });
            if (response.error) {
                window.alert(response.error);
                return;
            }
            const { product, variants } = response;
            setShirt(product);
            setSelectedShirt(product);
            setShirtVariants(variants);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            window.alert(e.message);
        }
    }

    // useEffect(() => {
    //     getShirtProduct();
    // }, [user]);

    function selectVariant({ variantId }) {
        let v = shirtVariants.filter((va) => va.product._id === variantId);
        if (v.length > 0) {
            setSelectedShirt(v[0]);
            console.log(v[0].bonds);
        }
    }

    async function getSwag() {
        console.log("getting swag");
        try {
            setBuyBondOpen(true);
        } catch (e) {
            console.log(e);
        }
    }

    async function getAltcoinOwnership() {
        try {
            const altcoinsResponse = await bondAPI.getAltcoinsBalance({ address: ethereumAddress })
            if (altcoinsResponse.error) {
                throw Error(altcoinsResponse.error)
            }

            setAltcoinsBalance(altcoinsResponse.balanceOf)
        } catch (e) {
            setAltcoinsBalance(0)
        }
    }

    async function goToBuy({ bondId, match }) {
        if (!match) {
            window.alert("You do not match the criteria for this option");
            return;
        }

        navigate(`/drip/buy?p=${selectedShirt.product._id}&b=${bondId}`);
    }

    async function submitPhoto() {
        try {
            if (!selectedImage) {
                showSnackbar({
                    message: "Please Upload Image of Max",
                    severity: "error",
                });
                setIsLoading(false)

                return
            }
            if (!ethereumAddress || ethereumAddress.length !== sampleAddy.length) {
                showSnackbar({
                    message: "Please Input Valid ETH Address",
                    severity: "error",
                });
                setIsLoading(false)

                return
            }


            setIsLoading(true)
            try {
                const fbResp = await await bondAPI.getTemporaryFBToken()

                await firebaseUtils.signinWithFirebaseToken(fbResp.firebaseToken);
            } catch (e) {
                throw Error("Error Uploading Image")
                console.log(e);
            }
            let avatarUrl
            const avatarUploadResponse = await firebaseUtils.uploadMax(
                selectedImage
            );
            if (avatarUploadResponse.error) {
                showSnackbar({
                    message: avatarUploadResponse.error,
                    severity: "error",
                });
                setIsLoading(false);
                return;
            }
            avatarUrl = avatarUploadResponse.downloadUrl;

            const submitResponse = await bondAPI.submitMaxPhoto({ maxPhotoUrl: avatarUrl, twitterUrl: tweetUrl, address: ethereumAddress })
            if (submitResponse.error) {
                throw Error(submitResponse.error)
            }

            setHasSubmitted(true)
            setIsLoading(false)
        } catch (e) {
            showSnackbar({
                message: e,
                severity: "error",
            });
            setIsLoading(false);
            return;
        }
    }

    return (
        <div>
            <WidthContainer desktopWidth={"40%"}>
                <Row>
                    <Col>
                        <SizedBox height={24} />
                        <Row>
                            <Col>
                                <DescriptionText>{"ALTCOINS PRESENTS"}</DescriptionText>

                                <TitleText>Stalk MAX!!</TitleText>
                                <DescriptionText>The rules are simple. Take a photo of Max and upload it along with an Ethereum address.</DescriptionText>
                                <DescriptionText>Earn extra points by owning an AltCoin or posting the picture on twitter</DescriptionText>

                            </Col>
                        </Row>
                        {isLoading && (
                            <Col>
                                <SizedBox height="64px" />
                                <LoadingIndicator />
                            </Col>
                        )}
                        {!isLoading && !hasSubmitted && (
                            <Col>
                                <SizedBox height={24} />
                                <Row>
                                    <UploadAndDisplayImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
                                </Row>
                                <SizedBox height={30} />
                                <Row>
                                    <Col>
                                        <SubtitleText>
                                            Step 2: Paste your ethereum Address
                                        </SubtitleText>
                                        <CustomTextField name={"ETH Address"} placeholder={"0x123..."} value={ethereumAddress} setValue={setEthereumAddress} />
                                    </Col>
                                </Row>
                                <SizedBox height={30} />
                                <Row>
                                    <Col>
                                        <SubtitleText>
                                            Step 3 Optional: Paste a link to a tweet with the Max picture and the #altcoins or #stalkmax hashtag
                                        </SubtitleText>
                                        <CustomTextField name={"Tweet URL"} placeholder={"https://twitter.com/abc123"} value={tweetUrl} setValue={setTweetUrl} />
                                    </Col>
                                </Row>
                                <SizedBox height={30} />
                                {ethereumAddress && ethereumAddress.length === sampleAddy.length && <Row>
                                    <Col>
                                        <SubtitleText>
                                            Step 4 Optional: Own an AltCoin
                                        </SubtitleText>
                                        <SubtitleText>{`AltCoins Balance: ${altcoinsBalance} ${altcoinsBalance > 0 ? "🤤" : "🤬"}`}</SubtitleText>
                                    </Col>
                                </Row>}
                                {/* <Row>
                                    <div className="">
                                        <FreeShirt shirt={shirt} variants={shirtVariants} />
                                    </div>
                                </Row>
                                <Row>
                                    <VariantSelector
                                        variants={shirtVariants}
                                        setVariant={selectVariant}
                                    />
                                </Row> */}
                                <SizedBox height={"24px"} />
                                <DescriptionText>{`p.s. You'll earn a better reward if you tweet or own an altcoin `}</DescriptionText>
                                <SizedBox height={"8px"} />

                                <DescriptionText>{`< You can only submit once >`}</DescriptionText>
                                <SizedBox height={"24px"} />
                                <Row>
                                    <Col>
                                        <MediumButton
                                            text={"Submit Photo"}
                                            onClick={submitPhoto}
                                            width={"100%"}
                                        />
                                    </Col>
                                </Row>
                                <SizedBox height={64} />
                            </Col>
                        )}
                        {!isLoading && hasSubmitted && (
                            <Col>
                                <SizedBox height={32} />
                                <TitleText>
                                    Thank you for submitting!
                                </TitleText>
                                <SizedBox height={16} />
                                <LoadingIndicator />
                                <SizedBox height={16} />

                                <SubtitleText>
                                    Stay tuned while we actually figure out what reward we should give you! 🥰
                                </SubtitleText>
                                <SizedBox height={16} />

                                <Row>
                                    <Col>
                                        <SocialButton width="80%" type={"telegram"} />
                                    </Col>
                                    <Col>
                                        <SocialButton width="80%" type={"discord"} />
                                    </Col>
                                    <Col>
                                        <SocialButton width="80%" type={"twitter"} />
                                    </Col>
                                </Row>
                                <SizedBox height={64} />

                            </Col>
                        )}
                    </Col>
                </Row>
            </WidthContainer>
            {/* {selectedShirt && (
                <BuyBondModal
                    open={buyBondOpen}
                    setOpen={setBuyBondOpen}
                    bonds={selectedShirt.bonds}
                    selectBond={goToBuy}
                />
            )} */}
        </div>
    );
}
