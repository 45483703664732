import React, { useState } from "react";
import { SizedBox } from "./SizedBox";
import { SubtitleText } from "./SubtitleText";
import { TitleText } from "./TitleText";

const maxImg = "https://firebasestorage.googleapis.com/v0/b/scedule-bond.appspot.com/o/max-cool-bg.png?alt=media&token=68a31c16-64da-4220-a88a-dff02a3b2d84"
const UploadAndDisplayImage = ({selectedImage, setSelectedImage}) => {

  return (
    <div>
      <SubtitleText>Step 1: Upload a photo of MAX</SubtitleText>
      <SizedBox height={12} />
      {selectedImage && (
        <div>
        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
        <br />
        <button onClick={()=>setSelectedImage(null)}>Remove</button>
        </div>
      )}
      {!selectedImage && (
        <div>
        <img style={{"border": "4px solid white"}} alt="not fount" width={"250px"} src={maxImg} />
        </div>
      )}
      <br />
     
      <br /> 
      
      <input
        type="file"
        name="myImage"
        accept="image/*"
        onChange={(event) => {
          if(event.target.files && event.target.files[0]){
            console.log(event.target.files[0]);
            setSelectedImage(event.target.files[0]);
          } else {
            setSelectedImage(undefined)
        }
        }}
      />
    </div>
  );
};

export default UploadAndDisplayImage;