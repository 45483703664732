import React, { useContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import { Container, Col, Row } from "react-bootstrap";
import SpinningCoinRGB from "../components/SpinningCoinRGB";
import MediumButton from "../components/MediumButton";
import { SizedBox } from "../components/SizedBox";
import SocialButton from "../components/SocialButton";
import pizza from "../components/media/pizza.png";
import computer from "../components/media/computer.png";
import vend from "../components/media/sodamachine.gif";
import { Web3Context } from "../contexts/Web3Context";
import { CustomTextField } from "../components/CustomTextField";
import { Center } from "../components/Center";
import train from "../components/media/Train.gif"
import DescriptionText from "../components/DescriptionText";
import { Tweet } from "react-twitter-widgets";
import { BrowserView, MobileView } from "react-device-detect";
import { TitleText } from "../components/TitleText";
import { SubtitleText } from "../components/SubtitleText";

import { WidthContainer } from "../components/WidthContainer";

export default function Mint() {
  const { altCoins, signer, account, paused, isWhitelistActive, mintNFTHandler, whitelistAmount, mintCount, publicAmount, totalAmount, totalSupply, isMinting } = useContext(Web3Context)
  const [buttonText, setButtonText] = useState("Minting Soon")
  const [canMint, setCanMint] = useState(false)
  const [quantity, setQuantity] = useState("")
  const [maxMint, setMaxMint] = useState(0)

  function calcButtonText() {
    try {
      setCanMint(false)
      setMaxMint((0).toString())

      if (!altCoins || !account) {
        setButtonText("Mint 08/17/22")
        return
      }

      if (totalAmount.toNumber() === totalSupply.toNumber()) {
        setButtonText("Sold out!")
        return
      }

      if (paused) {
        setButtonText("Minting Soon")
        return
      }
      if (isWhitelistActive) {
        if (whitelistAmount && whitelistAmount.toNumber() > 0) {
          const maxP = whitelistAmount.toNumber()
          const maxV = totalAmount.toNumber() - totalSupply.toNumber()
          setMaxMint((maxP > maxV ? maxV : maxP).toString())
          setButtonText("Mint Now")
          setCanMint(true)
          return
        }

        setButtonText("Public Mint Soon")
        return
      }


      if (typeof mintCount !== "undefined" && typeof publicAmount !== "undefined" && publicAmount.toNumber() > mintCount.toNumber()) {
        const maxP = publicAmount.toNumber() - mintCount.toNumber()
        const maxV = totalAmount.toNumber() - totalSupply.toNumber()
        setMaxMint((maxP > maxV ? maxV : maxP).toString())
        setButtonText("MINT NOW")
        setCanMint(true)
        return
      }

      setButtonText("FORBIDDEN FROM HOARDING MORE")
      return
    } catch (e) {
      setCanMint(false)

    }
  }

  useEffect(() => {
    calcButtonText()
  }, [paused, isWhitelistActive, whitelistAmount, mintCount, publicAmount, totalAmount, totalSupply])

  /*const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(undefined);
  const { mintNFTHandler, ownedAllowedNfts, ownedTokens } =
    useContext(Web3Context);
  const [selection, setSelection] = useState(ownedAllowedNfts[0]);
  const [currentlyMinting, setCurrentlyMinting] = useState(false);
  const [networkId, setNetworkId] = useState(undefined);
  async function mint() {
  //if(whitelisted)
    if (ownedAllowedNfts.length > 0) {
      setCurrentlyMinting(true);
      mintNFTHandler({
        tokenAddress: selection.address,
        tokenId: selection.tokenId,
      });
      setCurrentlyMinting(false);
    }
  }*/




  //did not change anything under here, if things are messed up, delete everything above and it will work just the same as before
  return (
    <>
      <BrowserView>
        <div className="mint-body" style={{ padding: "25px" }}>


          <h1 style={{ fontSize: "5rem" }}>AltCoins</h1>
          <h3 style={{ fontSize: "3rem" }}>100,000 completely unique AltCoins</h3>
          <h3 style={{ fontSize: "2rem" }}>with pictures!</h3>
          <div style={{ padding: "45px" }}>
            <SpinningCoinRGB />
          </div>

          <SizedBox height={"24px"} />
          {/* <Center>
            <div style={{ width: "30%" }}>
              <Row>
                <Col>
                  {altCoins && signer && maxMint && maxMint.toString() !== "0" &&
                    <Center>
                      <DescriptionText>
                        {`Max Mint ${maxMint}!!`}
                      </DescriptionText>
                    </Center>}
                  {altCoins && signer && maxMint && maxMint.toString() !== "0" && <CustomTextField
                    value={quantity}
                    setValue={setQuantity}
                    placeholder={`INPUT QUANTITY < MAX ${maxMint} >`}
                    type={"number"}
                    name={"ALTCOIN QUANTITY"}
                  />}
                </Col>
              </Row>
            </div>
          </Center> */}
          {/*{isMinting ? <MediumButton text={"Loading..."} onClick={() => {}} /> : <MediumButton text={buttonText} onClick={canMint ? async () => {
          if(!quantity || quantity.length ===0 ){
            window.alert("Please input a quantity")
            return
          }
          try{
            const max = parseInt(maxMint)
            const q = parseInt(quantity)
            if(q > max){
              window.alert("You cannot mint more than " + max + "!!!!")
              return
            }
             await mintNFTHandler(quantity)
            setQuantity("")
          } catch(e){
            window.alert("Please put in a valid quantity")
          }
        } : () => {}} />}*/}
                  

          <SizedBox height={"48px"} />

          <SizedBox height="100px" />

          <h1>Coming soon</h1>

          <SizedBox height="50px" />

          <img src={vend} width="15%" />

          <SizedBox height="36px" />

          <h3>Vend-o-matic</h3>

          <SizedBox height="200px" />
          <img src={train} width="85%" />
          <SizedBox height="200px" />

          <h4>Metaverse loading</h4>
          <p>Choose your destiny</p>

          <Container style={{
            left: "0",
            right: "0",
            width: "100%",
            paddingBottom: "30px"
          }}>
            
            <a href="https://cyberspace.computer" style={{ padding: "25px" }}><img src={computer} width="10%" alt="My Computer" /></a>
          </Container>

          <SizedBox height={"100px"} />

          <Container style={{
            bottom: "0",
            left: "0",
            right: "0",
            width: "40%",
            paddingBottom: "0px"
          }}>
            <Row md={3}>
              <div style={{ padding: "10px" }}><SocialButton type={"etherscan"} /></div>
              {/*<div style={{ padding: "10px" }}><SocialButton type={"discord"} /></div>*/}
              <div style={{ padding: "10px" }}><SocialButton type={"twitter"} /></div>
            </Row>
          </Container>
        </div>
      </BrowserView>
      <MobileView>
        <WidthContainer>
          <Row>
            <Col>
              <Row>
                <Col>
                <SizedBox height={"16px"} />
                  <TitleText fontSize={"36px"}>
                    AltCoins
                  </TitleText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubtitleText>
                    100,000 completely unique AltCoins
                  </SubtitleText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <SubtitleText fontSize={"20px"}>
                    with pictures!
                  </SubtitleText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div style={{ padding: "45px" }}>
                    <SpinningCoinRGB width={"80%"} />
                  </div>
                </Col>
              </Row>

              <SizedBox height="36px" />

              <Row>
                <Col>
                  <TitleText>
                    Coming soon
                  </TitleText>
                </Col>
              </Row>
              <SizedBox height={"12px"} />
              <Row>
                <Col>
                  <img src={vend} alt="vendomatic" width="45%" />
                </Col>
              </Row>
              <SizedBox height={"12px"} />

              <Row>
                <Col>
                <SubtitleText>
                  Vend-o-matic
                </SubtitleText>
                </Col>
              </Row>
              <SizedBox height={"24px"} />

              <Row>
                <Col>
                  <img src={train} alt="train" width="85%" />
                </Col>
              </Row>
              <SizedBox height={"28px"} />

              <Row>
                <Col>
                  <SubtitleText>
                  Metaverse loading
                  </SubtitleText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DescriptionText>
                    Choose your destiny
                  </DescriptionText>
                </Col>
              </Row>
              <SizedBox height="16px" />
              <Row>
                <Col>
                 
                </Col>
                <Col>
                  <a href="https://cyberspace.computer" style={{ padding: "25px" }}><img src={computer} width="90%" alt="My Computer" /></a>
                </Col>
              </Row>
              <SizedBox height="24px" />
              <Row>
                <Col>
                <SocialButton width="80%" type={"etherscan"} />
                </Col>
                <Col>
                {/*<SocialButton width="80%" type={"discord"} />*/}
                </Col>
                <Col>
                <SocialButton width="80%" type={"twitter"} />
                </Col>
                </Row>
                <SizedBox height="64px" />
          
            </Col>
          </Row>
        </WidthContainer>
      </MobileView>
    </>
  )
}