import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Select from "react-select";
import countries from "../utils/countries.json";
import DescriptionText from "./DescriptionText";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    //   borderBottom: '1px dotted pink',
    color: "black",
    //   padding: 20,
  }),
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    //   width: 200,
    ...provided,
    borderRadius: "0px",
    padding: "20px",
    border: "2px solid black",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export function CountrySelector({ country, setCountry }) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let o = [];
    for (const c of countries) {
      let k = Object.keys(c)[0];
      o.push({
        label: k,
        value: c[k],
      });
    }
    setOptions(o);
  }, []);

  return (
    <div>
      <Row>
        <DescriptionText color="black">Select Country</DescriptionText>
      </Row>
      <Select
        options={options}
        value={country}
        isSearchable={true}
        placeholder="Select Your Country"
        onChange={(c) => setCountry(c)}
        styles={customStyles}
      />
    </div>
  );
}
