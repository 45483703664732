import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import FreeShirt from "../components/FreeShirt";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import { bondAPI } from "../utils/api";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { VariantSelector } from "../components/VariantSelector";
import MediumButton from "../components/MediumButton";
import "../components/Glitch.css";
import { BuyBondModal } from "../components/BuyBondModal";
import { useNavigate } from "react-router-dom";

export default function Swag() {
  const navigate = useNavigate();
  const [shirt, setShirt] = useState(undefined);
  const [selectedShirt, setSelectedShirt] = useState(undefined);
  const [shirtVariants, setShirtVariants] = useState(undefined);
  const [bonds, setBonds] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [buyBondOpen, setBuyBondOpen] = useState(false);

  const { jwt, user } = useContext(Web3Context);

  async function getShirtProduct() {
    try {
      setIsLoading(true);
      const response = await bondAPI.getProductData({
        productId: "63065d485ae37ff57368c16e",
        jwt: jwt,
      });
      if (response.error) {
        window.alert(response.error);
        return;
      }
      const { product, variants } = response;
      setShirt(product);
      setSelectedShirt(product);
      setShirtVariants(variants);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      window.alert(e.message);
    }
  }

  useEffect(() => {
    getShirtProduct();
  }, [user]);

  function selectVariant({ variantId }) {
    let v = shirtVariants.filter((va) => va.product._id === variantId);
    if (v.length > 0) {
      setSelectedShirt(v[0]);
      console.log(v[0].bonds);
    }
  }

  async function getSwag() {
    console.log("getting swag");
    try {
      setBuyBondOpen(true);
    } catch (e) {
      console.log(e);
    }
  }

  async function goToBuy({ bondId, match }) {
    if (!match) {
      window.alert("You do not match the criteria for this option");
      return;
    }

    navigate(`/drip/buy?p=${selectedShirt.product._id}&b=${bondId}`);
  }

  return (
    <div>
      <WidthContainer desktopWidth={"30%"}>
        <Row>
          <Col>
            <SizedBox height={24} />
            <Row>
              <Col>
                <TitleText>HOT MERCH!!</TitleText>
              </Col>
            </Row>
            {isLoading && (
              <Col>
                <SizedBox height="64px" />
                <LoadingIndicator />
              </Col>
            )}
            {!isLoading && (
              <Col>
                <Row>
                  <div className="">
                    <FreeShirt shirt={shirt} variants={shirtVariants} />
                  </div>
                </Row>
                <Row>
                  <VariantSelector
                    variants={shirtVariants}
                    setVariant={selectVariant}
                  />
                </Row>
                <SizedBox height={"24px"} />
                <Row>
                  <Col>
                    <MediumButton
                      text={"get drip"}
                      onClick={getSwag}
                      width={"100%"}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Col>
        </Row>
      </WidthContainer>
      {selectedShirt && (
        <BuyBondModal
          open={buyBondOpen}
          setOpen={setBuyBondOpen}
          bonds={selectedShirt.bonds}
          selectBond={goToBuy}
        />
      )}
    </div>
  );
}
