import styled from "styled-components";

const SubtitleTextWrapper = styled.div`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

export function SubtitleText({ children, color, fontSize, textAlign }) {
  return (
    <SubtitleTextWrapper
      color={color ? color : "white"}
      fontSize={fontSize}
      textAlign={textAlign}
    >
      {children}
    </SubtitleTextWrapper>
  );
}
