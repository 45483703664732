import styled from "styled-components";
import { BsFillCheckCircleFill } from "react-icons/bs";
import RuleView from "./RuleView";
import { Center } from "./Center";
import { isMobile } from "react-device-detect";

const BondViewWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-top: 2px solid ${(props) => props.borderColor};
  border-left: 2px solid ${(props) => props.borderColor};
  border-bottom: 2px solid ${(props) => props.borderColor};
  border-right: 2px solid ${(props) => props.borderColor};
  padding: 20px;
  cursor: pointer;
  background-color: ${(props) => "white"};
`;

const RulesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
`;

const MobileRulesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  font-weight: bold;
`;

const AndLink = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const SelectedCircle = styled.div`
  color: ${(props) => props.color};
  border-radius: 5000px;
  padding:2px;
  display:flex;
  align-items;center;
  justify-content:center;
`;
export default function BondView({
  bond,
  bondIndex,
  match,
  selected,
  provider,
  selectBond,
}) {
  const bondClass = match ? "active-gradient-selector" : "";
  return (
    <>
      <BondViewWrapper
        match={match}
        className={bondClass}
        selected={selected}
        borderColor={match ? "#37DC84" : "black"}
        onClick={() => {
          selectBond({ bondId: bond.id, match: match });
        }}
      >
        {isMobile ? (
          <>
            <MobileRulesWrapper>
              {bond.rules.map((r, ruleIndex) => (
                <>
                  <RuleView
                    key={"bond-" + bondIndex + "-rule-" + ruleIndex + "p"}
                    rule={r}
                    isMobile={isMobile}
                    provider={provider}
                  />
                </>
              ))}
            </MobileRulesWrapper>
          </>
        ) : (
          <>
            <RulesWrapper>
              {bond.rules.map((r, ruleIndex) => (
                <RuleView
                  key={"bond-" + bondIndex + "-rule-" + ruleIndex}
                  rule={r}
                  isMobile={isMobile}
                  provider={provider}
                />
              ))}
              <>
                {bond.rules.length > 1 && (
                  <AndLink key={"bond-" + bondIndex + "-rule-link"}></AndLink>
                )}
              </>
            </RulesWrapper>
          </>
        )}
        {selected ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SelectedCircle className="lighter-active-gradient" color={"grey"}>
              <BsFillCheckCircleFill size={20} />
            </SelectedCircle>
          </div>
        ) : null}
      </BondViewWrapper>
    </>
  );
}
