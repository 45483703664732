import styled from "styled-components";


export const IconButtonWrapper = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};
`;

export default function IconButton({
  Icon,
  onClick,
  backgroundColor,
  color,
  borderColor,
}) {
  return (
    <div>
      
        <IconButtonWrapper
          color={color ? color : "black"}
          backgroundColor={
            backgroundColor ? backgroundColor : "white"
          }
          borderColor={borderColor ? borderColor : "black"}
          onClick={onClick}
        >
          <Icon />
        </IconButtonWrapper>
    </div>
  );
}
