const config = {
  defaultAvatarUrl:
    "https://firebasestorage.googleapis.com/v0/b/scedule-bond.appspot.com/o/images%2Fdefault-avatars%2FDefaultPfp1.png?alt=media&token=554cb2c8-5e22-480d-abc4-970cd8f9279b",
  ruleTypes: {
    "nft-ownership": "NFT Ownership",
    "erc20-ownership": "ERC20 Ownership",
    "eth-ownership": "ETH Ownership",
    // "erc20-payment": "ERC20 Payment",
    // "eth-payment": "ETH Payment",
  },
  smartyStreetsReferrer: "altcoins.gg",
  smartyStreetsWebsiteKey: "132636393673376552",
};

export default config;
