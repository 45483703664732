import { useContext, useEffect, useState } from "react";
import { Web3Context } from "../contexts/Web3Context";
import styled from "styled-components"
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

const ConnectButtonWrapper = styled.div`
  width: ${props => props.width ? props.width : "30%"};
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: ${props => props.fontSize ? props.fontSize : "32px"};
  padding: 10px 20px 10px 20px;
  border: none;
  cursor: pointer;
`;

const dappUrl = "altcoins.gg";


export default function ConnectWalletButton() {
    const { account, web3Handler, login, logout } = useContext(Web3Context)
    const location = useLocation();

    const [deepLink, setDeepLink] = useState(
        "https://metamask.app.link/dapp/" +
        dappUrl +
        location.pathname +
        location.search
    );

    useEffect(() => {
        setDeepLink(
            "https://metamask.app.link/dapp/" +
            dappUrl +
            location.pathname +
            location.search
        );
    }, [location]);
    useEffect(() => { }, [account]);

    return (
        <>
            <ConnectButtonWrapper width={isMobile ? "90%" : "30%"} fontSize={isMobile ? "20px" : "32px"}>
                <>
                    <>
                        {isMobile && !window.ethereum ? <a  href={deepLink}>
                            Connect Wallet
                        </a>
                            :
                            <>
                                {account ? (
                                    // <a
                                    //     href={`https://etherscan.io/address/${account}`}
                                    //     target="_blank"
                                    //     rel="noopener noreferrer"
                                    //     className="connect-wallet-button">
                                    //     {account.slice(0, 5) + '...' + account.slice(38, 42)}
                                    // </a>
                                    <div
                                        onClick={logout}
                                        className="connect-wallet-button">
                                        {account.slice(0, 5) + '...' + account.slice(38, 42)}
                                    </div>
                                ) : (
                                    <div onClick={login} style={{ fontSize: isMobile ? "20px !important" : undefined }} className="connect-wallet-button">Connect Wallet</div>
                                )}
                            </>}
                    </>
                </>
            </ConnectButtonWrapper>
        </>
    )
}