import {useState, useEffect} from 'react';
import twitter from "./media/twitter.png";
import discord from "./media/discord.png";
import etherscan from "./media/etherscan.png";

export default function SocialButton({type, width}) {
    const [buttonImg, setButtonImg] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    useEffect(() => {
    switch(type){
        case "twitter":
            setButtonLink("https://twitter.com/coloredcoinsv2");
            setButtonImg(twitter);
            break;
        case "discord":
            setButtonLink("https://discord.gg/j4RjuhUbn9");
            setButtonImg(discord);
            break;

        case "etherscan":
            setButtonLink("https://etherscan.io/token/0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a");
            setButtonImg(etherscan);
            break
        default:
            console.log("Social button type did not match any known social media");
            break;
    }
},[]);

    return(
    <a href={buttonLink} >
        <img src={buttonImg} width={width ? width : "40%"} alt="social link"/>
    </a>
    );
}