import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddressPreview } from "../components/AddressPreview";
import BondsViewer from "../components/BondsViewer";
import DescriptionText from "../components/DescriptionText";
import FreeShirt from "../components/FreeShirt";
import MediumButton from "../components/MediumButton";
import { RequireShippingModal } from "../components/RequireShippingModal";
import { SizedBox } from "../components/SizedBox";
import { SubtitleText } from "../components/SubtitleText";
import { TitleText } from "../components/TitleText";
import { createVariantOption } from "../components/VariantSelector";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import { bondAPI } from "../utils/api";

export default function Buy() {
  const navigate = useNavigate();
  const { user, jwt, showSnackbar, log, login } = useContext(Web3Context);
  const [isLoading, setIsLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [productId, setProductId] = useState(searchParams.get("p"));
  const [bondId, setBondId] = useState(searchParams.get("b"));
  const [product, setProduct] = useState(undefined);
  const [bond, setBond] = useState(undefined);
  const [showShippingModal, setShowShippingModal] = useState(false);

  async function getInitialData() {
    try {
      setIsLoading(true);
      if (!productId) {
        showSnackbar({ message: "No Product", severity: "error" });
        return;
      }

      const response = await bondAPI.getProductData({
        productId: productId,
        jwt: jwt,
      });
      if (response.error) {
        throw Error(response.error);
      }

      let b = response.bonds
        ? response.bonds.filter((e) => e.id === bondId)
        : [];

      setProduct(response.product);
      if (b.length > 0) {
        setBond(b[0]);
      }

      console.log("bond: ", b);
      console.log("product: ", response.product);

      setIsLoading(false);
    } catch (e) {
      showSnackbar({ message: e, severity: "error" });
      setIsLoading(false);
    }
  }

  async function createNewOrder() {
    try {
      setIsLoading(true);
      const response = await bondAPI.createOrder({
        productId: productId,
        bondId: bondId,
        jwt: jwt,
      });

      console.log("resposne: ", response);

      if (response.error) {
        throw Error(response.error);
      }

      navigate(`/order-details?o=${response.order._id}`);
    } catch (e) {
      showSnackbar({ message: e, severity: "error" });
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <BrowserView>
        <WidthContainer>
          <Row>
            <>
              {isLoading ? (
                <Col></Col>
              ) : (
                <>
                  {product ? (
                    <>
                      <Col>
                        <SizedBox height={"32px"} />
                        <Row>
                          <TitleText>{product.product.title}</TitleText>
                        </Row>
                        <FreeShirt shirt={product} />
                        <SubtitleText>
                          {createVariantOption(product.product).label}
                        </SubtitleText>

                        <BondsViewer
                          bonds={[bond]}
                          otherUser={user}
                          user={user}
                        />

                        <SizedBox height={"16px"} />
                        {(!user ||
                          (user &&
                            !user.shippingAddress &&
                            !user.intShippingAddress)) && (
                          <MediumButton
                            width="100%"
                            text="Add Shipping Address"
                            onClick={() => {
                              if (user) {
                                setShowShippingModal(true);
                              } else {
                                login();
                              }
                            }}
                          />
                        )}
                        {user &&
                          (user.shippingAddress || user.intShippingAddress) && (
                            <Col>
                              <AddressPreview />
                              <MediumButton
                                width="100%"
                                text="Change Shipping Address"
                                onClick={() => {
                                  if (user) {
                                    setShowShippingModal(true);
                                  } else {
                                    login();
                                  }
                                }}
                              />
                            </Col>
                          )}
                        <SizedBox height={"24px"} />
                        <MediumButton
                          width="100%"
                          text="Place Order"
                          onClick={createNewOrder}
                        />
                        <SizedBox height={"64px"} />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col>
                        <Row>
                          <Row>
                            <TitleText>No Product Found</TitleText>
                          </Row>
                        </Row>
                      </Col>
                    </>
                  )}
                </>
              )}
            </>
          </Row>
        </WidthContainer>
      </BrowserView>
      <MobileView></MobileView>
      <RequireShippingModal
        show={showShippingModal}
        setShow={setShowShippingModal}
      />
    </>
  );
}
