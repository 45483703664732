import { useState, useEffect, useContext } from "react";

export function useWindowDimensions() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }, [window.innerHeight, window.innerWidth]);

  return {
    width: width,
    height: height,
  };
}
