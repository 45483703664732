import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { Web3Context } from "../contexts/Web3Context";
import { bondAPI } from "../utils/api";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { VariantSelector } from "../components/VariantSelector";
import MediumButton from "../components/MediumButton";
import "../components/Glitch.css";
import { BuyBondModal } from "../components/BuyBondModal";
import { useNavigate } from "react-router-dom";
import { SubtitleText } from "../components/SubtitleText";
import mergeImages from 'merge-images';
import AltCoins from "../backend/abis/AltCoins.json"
import { ethers } from "ethers";
import {Alchemy, Network} from "alchemy-sdk";
import checksbg from "../components/media/checksbg.png"
import * as SVG from "svg.js";
import {svgAsPngUri} from "save-svg-as-png"
import ReactDOMServer from 'react-dom/server'
import blankCoin from "../components/media/blank-coin.png";
import usedCoin from "../components/media/used-coin.png";
import ArrowL from "../components/media/ArrowL.png";
import ArrowR from "../components/media/ArrowR.png";
import zIndex from "@mui/material/styles/zIndex";
import styled from "styled-components";
import removeButton from "../components/media/removebutton.png"

const contractAddress = "0x1ffe11b9fb7f6ff1b153ab8608cf403ecaf9d44a";

const config = {
    apiKey:"_iTnnLNw8EhUEvdNP2xyK4BrtlDUW2NL",
    network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(10, 1fr);

`;

export default function Checks() {
  const navigate = useNavigate();
  const [shirt, setShirt] = useState(undefined);
  const [selectedShirt, setSelectedShirt] = useState(undefined);
  const [shirtVariants, setShirtVariants] = useState(undefined);
  const [bonds, setBonds] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [buyBondOpen, setBuyBondOpen] = useState(false);
  const [nftList, setNftList] = useState([]);
  const [checks, setChecks] = useState([checksbg]);
  const [coinIds, setCoinIds] = useState([]);
  const [canMint, setCanMint] = useState(false);
  const [image, setImage] = useState(<img src={checksbg} style={{width: "100%", display: "block"}} alt={"Checks"}/>);
  const [page, setPage] = useState(null);
  const [pages, setPages] = useState(null);

  const { jwt, user } = useContext(Web3Context);

  async function addToChecks(id) {
    if(!canMint && !coinIds.includes(id))
    {
        let ids = coinIds;
        ids.push(id);
        setCoinIds(ids);
        let svgStr = "https://assets.altcoins.gg/v1/images/" + id + ".svg";
        document.getElementById("svg" + id).src = usedCoin;
        let coins = checks;
        fetch(svgStr)
            .then(response => response.text())
            .then(svgString => {
                const parser = new DOMParser();
                const svgElement = parser.parseFromString(svgString, "image/svg+xml").documentElement;
                svgAsPngUri(svgElement, {width: 32}).then(uri =>
                    {
                        let newX = ((checks.length - 1) % 8) * 32 + 32;
                        let newY = (Math.floor((checks.length - 1 )/ 8)) * 32 + 32;

                        coins.push({src: uri, x: newX, y: newY});

                        setChecks(coins);
                        updateImage();
                    }
                    )
            })
            .catch(error => console.error(error));
    }
  }

  const updateImage = async () => {
    let b64t = ""; 
    b64t = await mergeImages(checks);
    console.log(b64t);
    setImage(<img src={b64t} style={{width: "100%", display: "block"}} alt={"Checks"}/>);
}

function removeCoin(index)
{
    console.log("trying to remove " + index)
    if(checks.length < index){
        let tempChecks = checks;
        tempChecks.splice(index, 1);
        setChecks(tempChecks);
        updateImage();
    }
}

function turnPage(dir) {
    switch(dir)
    {
        case -1:
            if(page > 0)
            {
                setPage(page - 1);
            }
            break;
        case 1:
            if(page < pages.length - 1)
            {
                setPage(page + 1);
            }
    }
    setNftList(<div>{pages[page]}</div>);
}

const getNFTs = async () => {
    try{
        const { ethereum } = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        //const nftcontract = new ethers.Contract(contractAddress, AltCoins, provider)
        console.log(user);
        let ownedNfts = await alchemy.nft.getNftsForOwner(user.evmAddress, [contractAddress])
        console.log(ownedNfts.ownedNfts);

        let newNftList = [];
        //get list of nft images
        ownedNfts.ownedNfts.forEach(el => {
            let numstr = el.tokenId;
            newNftList.push(
                <Col>
                    <img src={"https://assets.altcoins.gg/v1/images/" + numstr + ".svg"} width={"100%"} id={"svg" + numstr} onClick={() => {addToChecks(numstr)}}/>
                </Col>
            )
            
            let pagesArr = [];
            let rows = [];
            let cols = [];

            for(let i = 0; i < newNftList.length; i++)
            {
                cols.push(newNftList[i]);

                if(cols.length == 5)
                {
                    rows.push(
                        <Row>
                            {cols}
                        </Row>);
                    cols = [];
                }
                if(rows.length == 5)
                {
                    rows.push(<Row><Col></Col><Col><img src={ArrowL} id={"ArrowL"} width={"100%"} onClick={() => turnPage(-1)}/></Col><Col></Col><Col><img src={ArrowR} id="ArrowR" width={"100%"} onClick={() => turnPage(1)}/></Col><Col></Col></Row>)
                    pagesArr.push(rows);
                    rows = [];
                }
            }
            if(cols.length !== 0 && cols.length < 5)
            {
                for(let j = cols.length; j < 5; j++)
                {
                    cols.push(<Col><img src={blankCoin} width={"100%"}/></Col>);
                }
                rows.push(<Row>{cols}</Row>);
            }

            if(rows.length < 5 && rows.length !== 0)
            {
                for(let k = rows.length; k < 5; k++)
                {
                    rows.push(<Row><Col><img src={blankCoin} width={"100%"}/></Col><Col><img src={blankCoin} width={"100%"}/></Col><Col><img src={blankCoin} width={"100%"}/></Col><Col><img src={blankCoin} width={"100%"}/></Col><Col><img src={blankCoin} width={"100%"}/></Col></Row>)
                }
                rows.push(<Row><Col></Col><Col><img src={ArrowL} width={"100%"} /></Col><Col></Col><Col><img src={ArrowR} width={"100%"}/></Col><Col></Col></Row>)
                pagesArr.push(rows)
                setPages(pagesArr)
            }
            setNftList(<div>{pagesArr[page]}</div>);
            setPage(0);
        });
        } catch (e) {
            console.log(e);
        }
}


useEffect(() => {
    if(user)
    {
        getNFTs();
    }
}, [user])

useEffect(() => {
    if(page === 0)
    {
        if(document.querySelector("ArrowL"))
            document.getElementById("ArrowL").style.opacity = ".5";
    }
}, [page])
    let removeRows = [];
    let removeCols = [];
    let idNum = 1;
    for(let i = 0; i < 10; i++)
    {
        for(let j = 0; j < 8; j++)
        {
            //TODO, this is wrong
            removeCols.push(<div style={{ width: "11%"}}><img src={removeButton} style={{opacity: "0", width: "100%"}}  onClick={(() => removeCoin(idNum))}/></div>);
            idNum++;
        }
        removeRows.push(<div style={{width: "100%", display: "flex"}}>{removeCols}</div>);
        removeCols = [];
    }

  return (
    <div>
      <WidthContainer desktopWidth={"30%"}>
        <Row>
          <Col>
            <SizedBox height={24}/>
            <Row>
              <Col>
                <TitleText>Coin Checks!</TitleText>
                <SubtitleText>Create your own masterpiece using 80 AltCoins!</SubtitleText>
              </Col>
            </Row>
            <div style={{ padding: "5px", backgroundColor: "#999999", width: "100%", height: "auto", margin: "auto"}}>
                                        {user ? 
                                            <div>{nftList}</div>
                                        :    <></>}
                                    </div>
           
          </Col>
        </Row>
        <Row>
                                <Col>
                                
                                <div style={{padding: "5px", width: "100%", margin: "auto", position: "relative"}}>
                                <div style={{position: "absolute", width: "97%", height: "100%", zIndex: 10, margin: "5%"}}>{removeRows}</div>
                                        <div style={{position: "relative", zIndex: 0, display: "flex"}}>
                                        {image}
                                        </div>
                                        <div></div>
                                    </div>
                                </Col>
                            </Row>
      </WidthContainer>
    </div>
  );
}
